@import "scss/lib.module.scss";

.saveDatabase {
  margin-bottom: $spacingXxxs;
}
.snapshotItem {
  margin-bottom: $spacingXxxs;
  position: relative;
  .snapshotItemControl {
    position: absolute;
    top: 0;
    left: -70px;
    width: $spacingXl;
    align-items: center;
    opacity: 0;
    transition: opacity 0.4s;
  }
  &:hover {
    .snapshotItemControl {
      opacity: 1;
    }
  }
}
.snapshotTitle {
  line-height: $lineHeightSm;
}
.snapshotDate {
  color: $darkBlue;
  font-size: $fontSizeXxxxs;
  text-decoration: none;
  margin-top: 0.03rem;
  line-height: $lineHeightMicro;
}
.snapshotHeader {
  margin: $spacingXxs 0;
}

.snapshotSelected {
  text-decoration: underline;
}
