@import "scss/lib.module.scss";

.table {
  border-collapse: collapse;
  th {
    text-align: left;
    font-size: $fontSizeXxs;
    padding: $spacingMicro;
  }
  td {
    padding: $spacingNano $spacingMicro;
    border-top: 1px solid $darkBlue;
  }
}

.newValue span {
  font-weight: $fontWeightBold;
}
.decreaseIncrese {
  width: 14px;
}
.decreaseIncreaseHeader {
  width: 30px;
  text-align: center;
}
.increased {
  color: $mediumBlue;
}
.decreased {
  color: $electricBlue;
}
