@import "scss/lib.module.scss";

.economicModel {
  h3 {
    margin-top: 0;
  }
  form > div {
    background-color: $coolGray6;
    padding: $spacingNano;
    border-radius: $borderRadius4;
    margin-bottom: $spacingNano;
  }
}

.detailsRow {
  td {
    padding-top: $spacingNano;
    padding-bottom: $spacingNano;
    display: table-cell;

    > div {
      display: flex;
      justify-content: space-between;
      padding-right: $spacingXxs;
      > input {
        width: $spacingXl;
        margin-right: $spacingXxs;
      }
    }
  }
  td:first-child {
    padding-left: $spacingXxl;
  }
}
.displayValue {
  margin-left: $spacingNano;
  display: block;
  text-align: left;
  font-weight: bold;
  color: $darkBlue;
}
.smallInput {
  width: 10rem !important;
  text-align: right !important;
  margin-right: $spacingSub !important;
}

.percentInputs {
  width: 10rem !important;
  text-align: right !important;
  margin-right: $spacingSub !important;
}

.calculating {
  span {
    opacity: 0.5;
  }
}

.disclaimer {
  color: $coolGray2;
  padding-top: $spacingXxs;
  padding-bottom: $spacingSub;
}
.total {
  color: $electricBlue !important;
}
.removeAccent {
  font-weight: $fontWeightRegular !important;
  text-align: right !important;
  padding-right: 1rem;
  margin-left: 0 !important;
  box-sizing: border-box;
  width: 10rem !important;
  text-align: right !important;
  margin-right: $spacingSub !important;
}
.spacingLeft {
  td:first-child {
    padding-left: 5.5rem;
  }
}
.spacingLeftNonArrow {
  td:first-child {
    padding-left: 5.5rem;
  }
}
