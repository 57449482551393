.actionBar {
  display: flex;
  align-items: center;
  & > * {
    flex: 1 1 12rem;
    padding: 0.5rem 0;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  & > *:first-child {
    margin-left: 0;
  }
  & > *:last-child {
    margin-right: 0;
  }
}
