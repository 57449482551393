@import "../../scss/lib.module";

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0,0,0, .3);
  overflow: scroll;
  z-index: 2;
}
