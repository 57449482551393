.inspect {
  padding: 1em;
  border-radius: 0.4em;
  background: #eee;
}
.inspectButton {
  background: transparent;
  color: transparent;
  border-color: transparent;
}
