@import-normalize;

@import "~@homelight/particle-tokens/dist/rem-scaled/tokens.scss";

html {
  font-size: 62.5%;
  box-sizing: border-box;
  line-height: 1.4;
  -webkit-text-size-adjust: 100%; // Prevent adjustments of font size after orientation changes in iOS
}

body {
  font-family: "Open Sans", $openSans, $centraNo2, "notoSans", "San Francisco", "-apple-system",
    "HelveticaNeue", helvetica, roboto, droid serif, arial, sans-serif;
  font-size: $fontSizeXxs;
  font-style: normal;
  font-weight: normal;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

a {
  text-decoration: none;
  color: $electricBlue;
}

a:hover {
  text-decoration: underline;
}

h3 {
  margin: $spacingXxxs 0;
}

:global {
  @media print {
    .hidePrint {
      display: none;
    }
  }
}

hr {
  height: $borderWidth1;
  background: $coolGray4;
  border: 0 none;
  margin: $spacingXxs 0;
}
