.toastContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: fixed;
  top: 20px;
  right: 20px;
  transition: all 400ms ease-in-out;
}

.toast {
  max-height: 0;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  transition: all 400ms ease-in-out;
  background-color: #222222;
  box-shadow: 0 4px 20px 0 rgba($color: #000000, $alpha: 0.2);
  box-sizing: border-box;
}

.message {
  margin-top: 6px;
  padding-right: 20px;
  color: white;
}

.closeBtn {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.toastShow {
  animation: showToast 400ms ease-in-out forwards;
}

.toastHide {
  animation: hideToast 400ms ease-in-out forwards;
}

@keyframes showToast {
  0% {
    max-height: 0;
    right: -200px;
    opacity: 0;
  }
  100% {
    max-height: 500px;
    right: 0px;
    opacity: 1;
  }
}

@keyframes hideToast {
  0% {
    max-height: 500px;
    right: 0px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    right: -200px;
    opacity: 0;
  }
}
