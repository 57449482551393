@import "scss/lib.module.scss";
.economicModel {
  padding-bottom: 72px;
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

.mobilePropertyAddress {
  display: none;
}

.tabNavigation {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  h2 {
    height: 36px;
    font-size: 20px;
    margin-right: 54px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-weight: normal;
  }

  .activeTab {
    border-bottom: 4px solid $color-pitch-black;
    font-weight: 700;
  }
}

.tabNavBottom {
  margin-top: 0;
}

.hiddenTab {
  display: none;
}

.heading {
  display: flex;
  flex-direction: row;

  .bbysIcon {
    padding-top: 6px;
    height: inherit;
    margin-right: 6px;
    color: $color-electric-blue;
  }

  .headingText {
    font-size: 32px;
    letter-spacing: -0.8px;
  }
}

h3 {
  height: 48px;
  margin: 4px 0;
  display: flex;
  align-items: center;
}

hr {
  margin: 32px 0;
}

.bbysPageBody {
  padding-bottom: 72px;
  color: $color-pitch-black;
  tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 48px;
    margin: 4px 0;

    td {
      height: inherit;
      padding-top: 0;
      .moneyInput, .percentInputs, .moneyDisplay, .moneyDisplayNegative, .percentDisplay, .ratio {
        width: 100px;
        text-align: right;
        padding: 12px;
        font-size: 16px;
      }

      .moneyDisplay, .percentDisplay, .moneyDisplayNegative {
        width: 76px;
      }

      .moneyDisplayNegative {
        color: $color-alert-red;
      }

      .emptyVal {
        text-align: center;
        padding: 0 48px;
      }
    }

    td.label {
      font-size: 16px;
      width: 600px;
      display: flex;
      align-items: center;
    }

    .ratio, .ratioRed {
      font-weight: 700;
      font-size: 16px;
    }
    .ratioRed {
      color: $color-alert-red;
    }

    .cell {
      width: 280px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
    }

    .sideButton {
      position: absolute;
      left: calc(100% + 0.5rem);
      height: 100%;
      border-color: #DBDFE6;
      color: #273653;
    }
  }
  .collapsible {
    color: black;
  }
  .subcategory {
    padding-left: 32px;
  }

  .totalRow {
    font-weight: 700;
  }

  .finalRow {
    background-color: inherit;
    font-weight: 700;
  }

  .highlitedVal, .finalVal {
    color: $color-blue-blue;
    font-weight: 700;
  }

  .finalVal {
    font-size: 18px;
  }

  tr.collapsible {
    .percentDisplay, .moneyDisplay, .moneyDisplayNegative {
      text-align: right;
      padding: 12px;
      font-size: 16px;
      width: 76px;
    }
  }
}

.addressPart {
  font-size: 20px;
  font-weight: 700;
}

.homeLightHomeLoans {
  margin-top: 16px;
  a {
    span {
      line-height: $lineHeightXs;
    }
    svg {
      width: 1.4rem;
      height: 1.4rem;
      margin-left: 0.7rem;
    }
  }
}

@media (max-width: $breakpt-small) {
  .mobilePropertyAddress {
    display: flex;
    flex-direction: column;
  }

  .heading {
    .headingText {
      font-size: 20px;
    }
  }

  h3 {
    font-size: 14px;
  }

  .bbysPageBody {
    tr.collapsible {
      .percentDisplay, .moneyDisplay, .moneyDisplayNegative {
        font-size: 14px;
      }
    }
    tr td.label {
      font-size: 14px;
    }

    tr {
      td {
        .moneyDisplay, .moneyDisplayNegative, .percentDisplay, .moneyInput, .percentInputs {
          font-size: 14px;
          padding: 10px 12px;
        }

        .moneyDisplay, .moneyDisplayNegative, .percentDisplay {
          height: 20px;
        }

        .moneyInput, .percentInputs {
          height: 40px;
        }

        .cell {
          width: 200px;
        }
      }
    }
    .subcategory {
      padding-left: 16px;
    }
  }

  .tabNavigation {
    h2 {
      font-size: 14px;
      margin-right: 16px;
    }
  }

  hr {
    margin-bottom: 24px;
  }
  tr {
    height: 40px;
    label {
      font-size: 14px;
    }
  }
}
