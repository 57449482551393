@import "scss/lib.module.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (max-width: $breakpt-small) {
    flex-direction: column-reverse;
  }
}

.sidebar {
  min-width: 300px;
  max-width: 300px;
  background-color: $white;
  position: sticky;
  overflow-y: auto;

  @media (max-width: $breakpt-small) {
    display: none;
  }
}

.overlay {
  position: fixed;
  top: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: $color-black;
}


.main {
  max-width: 1040px;
  background-color: $white;
  padding-top: $spacingXl;
  padding-left: 112px;
  padding-right: 112px;

  @media (max-width: $breakpt-small) {
    padding: 32px 20px;
  }
}

.snapshotTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.snapshotDate {
  font-size: $fontSizeXxs;
  font-weight: normal;
  color: $coolGray2;
}
.title {
  font-size: $fontSizeLg;
  color: $darkBlue;
  line-height: $lineHeightH2;
  letter-spacing: $spacingN8;
}
