@import "scss/lib.module.scss";
.loading {
  opacity: 0.5;
}

.mobileFooter {
  @media (min-width: $breakpt-small) {
    display: none;
  }
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: $color-white;
  box-shadow: 0px 1px 0px 0px $color-faint-gray inset;

  .footerMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $color-white;
    padding: 16px 0;
    box-shadow: 0px 1px 0px 0px $color-faint-gray inset;
  }
  .footerButton, .footerButtonDisabled {
    max-width: 186px;
    margin-right: 24px;
  }
  .footerButtonDisabled {
    border-color: $color-light-gray;
    background-color: $color-light-gray;
    pointer-events: none;
  }
}

.historyBodyWeb {
  max-height: none;
}

.historyHeader {
  justify-content: center;
  border-bottom: 1px solid $color-faint-gray;
  border-radius: 12px 12px 0px 0px;
}
.snapshotItem {
  .snapshotItemControl {
    display: flex;
    flex-direction: row;
  }
  &:hover {
    background-color: $color-faint-gray;
    border-radius: 8px;

    .editIcon {
      opacity: 1;
      display: flex;
      color: $color-pitch-black;
    }
  }
}

  .snapshotItem, .editSnapshotItem {
    border-radius: 8px;
    width: auto;
    padding: 10px;
    display: flex;
    position: relative;
    right: 10px;
    justify-content: flex-start;
    .editIcon {
      opacity: 0;
    }
    .caretLabel {
      top: 5px;
    }
  }
  .editSnapshotItem {
    background-color: $color-faint-gray;
    input {
      font-family: inherit;
      width: auto;
      height: 20px;
    }

    .titleInput {
      background-color: $color-cool-gray-1;
      border-radius: 8px;
      user-select: all;
      -webkit-user-select: all;
      border: none;
      padding: 0;
      font-size: 14px;
      &:focus-visible {
        border-radius: 0;
        border-color: $color-pitch-black;
        height: inherit;
      }
    }
    button {
      display: none;
    }
  }

  .snapshotsList {
    max-height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100% + 20px);
    padding: 10px;
    position: relative;
    right: 10px;
    @media (max-width: $breakpt-small) {
      max-height: 180px;
      padding: 22px 10px;
      width: auto;

      .snapshotItem, .editSnapshotItem {
        right: 0;
        left: 10px;
        width: calc(100% - 30px);
        .snapshotItemText {
          width: 100%;
        }

        .snapshotItemControl {
          justify-content: space-between;
          width: 100%;
        }
        .editIcon {
          display: flex;
          opacity: 1;
        }
      }
    }
  }

  .snapshotTitle > a {
    color: $color-pitch-black;
  }
  .snapshotDescription {
    font-size: 12px;
    color: $color-warm-light-gray-2;
    padding-top: 3px;
  }


.historyToggle {
  max-width: 120px;
  position: relative;
  bottom: 2px;
}
.historyDropdown {
  margin: 24px 0 0 0;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}

.historyDropdownMobile {
  margin-left: 24px;
  display: flex;
  position: relative;
  top: 8px;
}
