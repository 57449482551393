@import "scss/lib.module.scss";

.form {
  display: flex;
  flex-direction: column;
  min-width: 68rem;
  max-width: 80rem;
  padding: 2rem 2rem 3rem;
}

.form {
  input,
  textarea,
  select {
    &:focus {
      outline-offset: -3px;
      outline-width: 1px;
      outline-color: $color-electric-blue;
    }
  }
}

.title {
  margin: 1rem 0;
}

input,
textarea,
select {
  line-height: $lineHeightSm;
  border-radius: $input-button-border-radius;
  border: solid 1px $input-border-color;
  padding: $input-padding;
  background: none;
  ::placeholder {
    color: $input-placeholder-color;
  }
}

.textArea {
  width: 100%;
  resize: vertical;
  height: 12rem;
}

.form {
  .checkBox,
  .radio {
    margin: 1px 3px;
    flex: 0 0 auto;
  }
  .inlineMode {
    align-items: normal;
  }
}

.select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.selectWrapper {
  position: relative;
  svg {
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -1.2rem;
    height: 2.4rem;
    width: 2.4rem;
    color: $color-electric-blue;
    pointer-events: none;
  }
}

.actions {
  margin-top: 20px;
  padding-bottom: 20px;

  button {
    margin-right: 10px;
    margin-left: 10px;
  }

  & > button:first-child {
    margin-left: 0;
  }
  & > button:last-child {
    margin-right: 0;
  }

  .confirmation {
    margin-left: 10px;
    color: $color-electric-green;
  }
  .error {
    margin-left: 10px;
    color: $color-alert-red;
  }
}

.form {
  .field {
    &.inline {
      padding-top: 1.5rem;
    }
  }
  label {
    margin-bottom: 0.5rem;
  }
}
.field {
  color: $form-text-color;
  display: flex;
  flex-direction: column;
  line-height: normal;
  min-height: fit-content;
  position: relative;
  text-overflow: ellipsis;

  @include ie_only {
    min-height: 60px;
  }

  &.inline {
    display: flex;
    label {
      display: inline-block;
      margin-left: 0.5rem;
      margin-bottom: 0;
    }
  }

  &.error input {
    border-color: $color-alert-red;
  }

  label {
    display: block;
    font-size: 1.2rem;
    font-weight: 600;
    color: $title-font-color;
  }
  .required {
    color: $color-ruby-red;
    margin-left: 0.2rem;
  }

  .hint {
    display: block;
    font-size: x-small;
    margin-top: 0.5rem;

    &.show {
      opacity: 1;
    }

    &.errorMsg {
      color: $color-alert-red;
    }
  }
}

.field + .field {
  margin-top: 2rem;
}

.inlineMode + .inlineMode {
  margin-top: 0.5rem;
}

.inlineMode {
  display: flex;
  align-items: baseline;
  & > * {
    flex: 1 1 auto;
  }
  label {
    flex: 0 0 39%;
    margin-right: 0.5rem;
  }
}

.checkBoxField {
  input {
    flex: 0 0;
  }
  label {
    flex: 0 0 auto;
    font-weight: 300;
    margin-left: 0.5rem;
  }
}

.passwordInputWrapper {
  position: relative;

  svg {
    color: $color-pitch-black;
    cursor: pointer;
    opacity: 0.7;
    position: absolute;
    right: 1rem;
    top: -0.25rem;
    transition: opacity 0.2s ease-out;
    width: 1.5rem;

    &:hover {
      opacity: 1;
    }
  }
}
