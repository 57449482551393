@import "scss/lib.module.scss";

.table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  input, select {
    border-radius: $borderRadius4;
    font-size: $fontSizeXxs;
    line-height: $lineHeightSm;
    padding: 7px 10px;
    background-color: $white;
    border: 1px solid $coolGray4;
    color: $darkBlue;
    text-align: left;
    box-sizing: border-box;
    width: 15rem;
  }
  input {
    -webkit-appearance: none;
  }
  input:focus {
    outline: none;
    border-color: $electricBlue;
    border-width: 2px;
    padding: 6px 9px;
  }
  td {
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    > span {
      margin-left: $spacingNano;
      display: block;
      text-align: left;
      font-weight: bold;
      color: $darkBlue;
    }
    padding-top: 1rem;
    vertical-align: middle;
  }
  tr:first-child td {
    margin-top: 0;
  }
}

.section {
  height: $spacingMd;
  font-weight: $fontWeightSemibold;
  font-size: $fontSizeXs;
  vertical-align: bottom;
}

.separator {
  height: 4rem;
  font-weight: $fontWeightRegular;
  font-size: $fontSizeXs;
  vertical-align: bottom;
  color: $darkBlue;
  text-decoration-line: underline;
  td {
    padding-top: $spacingXs;
    padding-bottom: $spacingXxs;
  }
}

.total {
  font-weight: bold;
}

.final {
  background-color: $white;
  font-weight: bold;
  td {
    height: auto;
  }
  td:first-child {
    padding: $spacingNano 0 $spacingNano $spacingXs;
    border-radius: $borderRadius8 0 0 $borderRadius8;
  }
  td:last-child {
    padding: $spacingNano $spacingXs $spacingNano 0;
    border-radius: 0 $borderRadius8 $borderRadius8 0;
  }
}

.label {
  color: $darkBlue;
  width: 40rem;
  padding-left: $spacingXs;
  height: $spacingXs;
  svg {
    margin-right: $spacingNano;
  }
  .summaryLabel {
    margin-left: -3.2rem;
  }
}

.summaryLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: $spacingNano;
    transition: all 0.1s;
  }
}
.summaryRow {
  td {
    padding-bottom: $spacingNano;
    padding-top: $spacingNano;
  }
  td:first-child {
    display: flex;
    align-items: center;
  }
}
.rotateRight {
  transform: rotate(-90deg);
}

td > div.caretLabel {
  justify-content: normal;

  svg {
    margin-left: 6px;
    position: relative;
    top: 8px;
  }
}
 td > div.flippedLabel {
  justify-content: normal;

  .flipped {
    transform: rotate(-180deg);
  }
  svg {
    position: relative;
    bottom: 7px;
    right: 5px;
  }
 }
