@import "scss/lib.module.scss";

.button {
  background: transparent;
  border-radius: $borderRadius4;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: $button-padding;
  line-height: $lineHeightSm;
  font-size: $fontSizeXxs;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.button:disabled {
  cursor: default;
}

.major {
  color: #fff;
  font-weight: 700;
  background-color: $color-midnight-blue;
  border-color: $color-midnight-blue;
  padding: $button-padding-large;
  font-size: 1.143em;
}

.create {
  color: #fff;
  background-color: $color-midnight-blue;
  border-color: $color-midnight-blue;
  font-weight: 700;
}

.large {
  padding: $button-padding-large;
  font-size: 1.8rem;
}

.medium {
  padding: $button-padding-medium;
  font-size: 1.6rem;
}

.primary {
  color: #fff;
  font-weight: 600;
  background-color: $color-electric-blue;
  border-color: $color-electric-blue;
  outline: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    box-shadow: 0 0 5px rgba($color-electric-blue, 0.2);
  }
}

.menuButton {
  color: $menu-item-color;
  text-decoration: none;
  display: block;
  padding: 3px 0;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  &:hover {
    color: $color-electric-blue;
  }
  &:focus {
    outline: none;
  }
}

.menuButton + .menuButton {
  margin: 0;
}

.primary:disabled {
  color: #fff;
  background-color: desaturate($color-electric-blue, 90%);
  border-color: desaturate($color-electric-blue, 90%);
  &:hover {
    box-shadow: none;
  }
}

.secondary {
  font-weight: 600;
  color: $electricBlue;
  background-color: $white;
  border: 1px solid $electricBlue;
  &:hover {
    border-color: $electricBlueHover;
    color: $electricBlueHover;
    background-color: $whiteHover;
  }
}

.icon,
.iconInverted {
  padding: 2px 8px;
  display: inline-flex;
  align-items: center;
  outline: 0;
  svg {
    height: 14px;
    width: 14px;
  }
}

.icon {
  color: $color-electric-blue;
  background-color: transparent;
  border-color: $color-electric-blue;
  &:disabled {
    border-color: $color-light-gray;
    color: $color-light-gray;
  }
}

.iconInverted {
  color: white;
  background-color: $color-electric-blue;
  border-color: $color-electric-blue;
  &:disabled {
    border-color: $color-light-gray;
    background-color: $color-light-gray;
  }
}

button.link {
  color: $body-link-color;
  background-color: transparent;
  outline: 0;
  display: inline-flex;
  align-items: center;
  text-align: left;
  border: 0;
  padding: 0;
  vertical-align: initial;
  white-space: normal;

  &:hover {
    text-decoration: underline;
  }

  svg {
    height: 1.4rem;
    width: 1.4rem;
    margin: 0 0.3rem;
  }

  &:global(.selected) {
    font-weight: bold;
    svg {
      path {
        stroke: currentColor;
      }
    }
  }
}

button.link:disabled {
  color: desaturate($body-link-color, 90%);
  &:hover {
    text-decoration: none;
  }
}

button.external {
  border-color: $color-electric-blue;
  color: $color-electric-blue;
  svg {
    margin-left: 10px;
    height: 12px;
    width: 12px;
  }
}

button.transparent {
  color: #6c757d;
  font-weight: 600;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

button.action {
  font-weight: 600;
  color: $color-electric-blue;
  background-color: transparent;
  background-image: none;
  border-color: $color-electric-blue;
  &:focus {
    outline: none;
  }
  &:active {
    box-shadow: 0 0 5px rgba($color-electric-blue, 0.2);
  }
}

button.action:disabled {
  color: desaturate($color-electric-blue, 90%);
  border-color: desaturate($color-electric-blue, 90%);
}

button.wrapper {
  padding: 0;
  border: 0;
  outline: 0;
}

button.toggle {
  padding: 0;
  border: 0;
  outline: 0;
  &:global(.selected) {
    font-weight: bold;
  }
}

.spinner {
  margin-right: 0.5rem;
}

.noBorder {
  border: 0 none;
}
.isBlock {
  display: block;
  width: 100%;
}
