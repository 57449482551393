$color-black: #000;
$color-charcoal: #444;
$color-electric-blue: #46b6ff;
$color-blue-blue: #1192E5;
$color-electric-green: #38bf8f;
$color-very-light-gray: #eee;
$color-warm-light-gray: #f5f6f9;
$color-warm-light-gray-2:#72757D;
$color-light-gray: #dddddd;
$color-cool-gray-1: #C5C8CD;
$color-gray: #888;
$color-dark-gray: #666;
$color-faint-gray: #dbdfe6;
$color-pale-blue: #d3e1ef;
$color-pitch-black: #273653;
$color-midnight-blue: #002b44;
$color-ruby-red: #bc3030;
$color-alert-red: #fe3b30;
$color-confirmation-green: #38bf8f;
$color-warning-yellow: #fec13b;
$color-smoke: #eef1f7;
$color-vapor: #f4f4ff;
$color-haze: rgba(238, 241, 247, 0.9);
$color-vibrant-blue: #0a54d8;
$color-white: #fff;
$color-yellow: #ffc13b;
$color-bronze: #d19c75;
$color-gold: #dda605;
$color-purple: #8e42a4;

$color-homeloans: #8e42a4;
$color-cash-close: #2e8b39;
$color-simple-sale: $color-vibrant-blue;
$color-closing-services: black;
$color-cash-offer: #45bf38;
$color-trade-in: #3192ba;
$color-disclosures-io: #2f4264;

// If these variables are to be overridden by other applications, then add the !default flag

$title-font-size: 2.4rem !default;

$alert-color: $color-ruby-red !default;
$background-color: $color-white !default;
$background-color-aside: $color-smoke !default;
$body-link-color: $color-electric-blue !default;
$body-icon-color: $color-charcoal !default;
$body-text-color: $color-pitch-black !default;
$body-secondary-text-color: lighten($color-pitch-black, 20%) !default;
$disabled-color: $color-gray !default;
$form-text-color: $color-dark-gray !default;
$header-link-color: $color-dark-gray !default;
$header-link-hover-color: $color-electric-blue !default;
$header-background-color: rgba($color-pitch-black, 0.85) !default;
$highlight-color: $color-vapor !default;
$primary-color: $color-vibrant-blue !default;
$sidebar-background-color: $color-pale-blue !default;
$site-background-color: $color-white !default;
$site-separator-color: $color-light-gray !default;
$site-separator-light-color: $color-smoke !default;
$soft-selection-color: $color-smoke !default;
$title-font-color: $color-pitch-black !default;
$modal-bg-color: $color-haze !default;

$menu-item-color: $color-dark-gray !default;
$menu-background-color: $color-white !default;

$input-border-color: $color-light-gray !default;
$input-placeholder-color: $color-smoke !default;
$input-focus-color: $color-charcoal !default;
$input-button-border-radius: 4px;

$input-padding: 0.5rem 1rem;
$input-padding-large: 1.3rem 2rem;

$button-padding: 0.6rem 1.2rem;
$button-padding-medium: 0.8rem 4rem;
$button-padding-large: 1.3rem 4rem;
$input-font-size: 1.2rem;
$input-font-size-large: 1.5rem;

$breakpt-small: 767px;
