@mixin appearance($val) {
  appearance: $val;
  -webkit-appearance: $val;
  -moz-appearance: $val;
}

@mixin media($point) {
  @if $point == belowDesktop {
    @media (max-width: 1159px) {
      @content;
    }
  } @else {
    @media (max-width: $point) {
      @content;
    }
  }
}

@mixin ie_only() {
  @media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
    @content;
  }
}
