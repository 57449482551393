@import "scss/lib.module.scss";

.section {
  margin-bottom: $spacingXxs;
  line-height: $lineHeightSm;
  .title {
    font-size: $fontSizeXxxs;
    line-height: $lineHeightXs;
  }
  div {
    margin-bottom: 3px;
  }
}
.sidebar {
  padding-top: $spacingXl;
  padding-left: 100px;
  padding-right: 40px;
}
.bbysSidebar {
  position: fixed;
  padding: 64px 40px;
  min-width: 275px;
  max-width: min-content;
  height: inherit;
  background-color: $color-warm-light-gray;
}

.logo {
  margin-bottom: $spacingXxxs;
}

.signout {
  margin-bottom: $spacingXxxs;
}

.homeLightHomeLoans {
  a {
    display: flex;
    align-content: center;
    span {
      line-height: $lineHeightXs;
    }
    svg {
      width: 1.4rem;
      height: 1.4rem;
      margin-left: 0.7rem;
    }
  }
}
